// The top-level (parent) route
const routes = {
    path: '/',
    load: () => import(/* webpackChunkName: 'layout' */ './layout'),
    // Keep in mind, routes are evaluated in order
    children: [
        {
            path: '/',
            load: () => import(/* webpackChunkName: 'home' */ '../pages/home'),
        },
        {
            path: '/ershoufang/list',
            load: () => import(/* webpackChunkName: 'tradelist' */ '../pages/list/trade'),
        },
        {
            path: '/zufang/list',
            load: () => import(/* webpackChunkName: 'rentlist' */ '../pages/list/rent'),
        },
        {
            path: '/xinfang/list',
            load: () => import(/* webpackChunkName: 'newhouselist' */ '../pages/list/newhouse'),
        },
        {
            path: '/ershoufang/detail',
            load: () => import(/* webpackChunkName: 'trade' */ '../pages/detail/trade'),
        },
        {
            path: '/zufang/detail',
            load: () => import(/* webpackChunkName: 'rent' */ '../pages/detail/rent'),
        },
        {
            path: '/xinfang/detail',
            load: () => import(/* webpackChunkName: 'newhouse' */ '../pages/detail/newhouse'),
        },
        {
            path: '/xinfang/xiangce',
            load: () => import(/* webpackChunkName: 'albums' */ '../pages/albums'),
        },
        {
            path: '/xinfang/huxing',
            load: () => import(/* webpackChunkName: 'frame' */ '../pages/frame'),
        },
        {
            path: '/xinfang/dongtai',
            load: () => import(/* webpackChunkName: 'news' */ '../pages/news'),
        },
        {
            path: '/policy',
            load: () => import(/* webpackChunkName: 'policy' */ '../pages/policy'),
        },
        {
            path: '/protocol',
            load: () => import(/* webpackChunkName: 'protocol' */ '../pages/protocol'),
        },
        {
            path: '/focushouse',
            load: () => import(/* webpackChunkName: 'chinahouse' */ '../pages/chinahouse'),
        },
        {
            path: '/mapfind',
            load: () => import(/* webpackChunkName: 'mapfind' */ '../pages/mapfind'),
        },
        {
            path: '/releaseHouse',
            load: () => import(/* webpackChunkName: 'releaseHouse' */ '../pages/releaseHouse'),
        },
        {
            path: '/404',
            load: () => import(/* webpackChunkName: '404' */ '../pages/404'),
        },
        {
            path: '(.*)',
            load: () => import(/* webpackChunkName: 'notfound' */ './notfound'),
        }
    ],
    async action ({ next }) {
        // Execute each child route until one of them return the result
        const route = await next();
        // Provide default values for title, description etc.
        route.title = `${route.title || '京日找房'}`;
        route.description = route.description || '京日找房';

        return route;
    },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
    routes.children.unshift({
        path: '/error',
        action: require('./error').default,
    });
}

export default routes;
